// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://tulipcargo.kz:3001',
   
    // nameCargo: 'TURKESTAN KARGO'
    nameCargo: 'TULIP CARGO'
  };
  
  
export default config;
